var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.upperLogo
    ? _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-layout",
            { attrs: { column: "", "fill-height": "", wrap: "" } },
            [
              _c(
                "v-container",
                {
                  staticClass: "mx-auto",
                  staticStyle: { width: "90%", "max-width": "450px" },
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "mt-12" },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-img", {
                            staticClass: "mx-auto",
                            attrs: {
                              src: _vm.upperLogo,
                              width: "100%",
                              "max-width": "200px",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-row", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "font-weight-black text-center fx-20 mx-auto",
                        staticStyle: {
                          "font-size": "20px",
                          "margin-top": "12px",
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("systemName")) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]),
                  _c(
                    "v-row",
                    { staticClass: "mt-12" },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-img", {
                            staticClass: "mx-auto",
                            attrs: {
                              src: require("@/assets/img/top.gif"),
                              width: "70vw",
                              "max-width": "350px",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "mt-2 ml-2 mr-2" },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-img", {
                            staticClass: "mx-auto",
                            attrs: {
                              src: require("@/assets/img/copylight.gif"),
                              width: "30vw",
                              "max-width": "100px",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.downerLogo
                    ? _c(
                        "v-row",
                        { staticClass: "mt-4" },
                        [
                          _c("v-img", {
                            staticClass: "mx-auto",
                            attrs: {
                              contain: true,
                              "max-height": "300px",
                              width: "100%",
                              "max-width": "200px",
                              src: _vm.downerLogo,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("p", { staticClass: "mt-4" }, [
                    _vm._v(_vm._s(_vm.message)),
                  ]),
                  _c(
                    "v-row",
                    { staticClass: "my-12" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "font-weight-black mx-auto",
                          staticStyle: { "font-size": "20px" },
                          attrs: {
                            align: "center",
                            href: "/login",
                            width: "70vw",
                            "max-width": "350px",
                            color: "next",
                            dark: "",
                            rounded: "",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("button.start")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }